import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Register from "./components/pages/Register";
import Navbar from "./components/Navbar/Navbar";
import Login from "./components/pages/Login";
import Forbidden from "./components/pages/Forbidden";
import ShowNavbar from "./components/Navbar/ShowNavbar";
import Challenges from "./components/pages/Days";
import Participantes from "./components/pages/Participantes";
import PainelAdmUser from "./components/pages/PainelAdmUser";
import TodayChallenges from "./components/pages/TodayChallenges";
import Ranking from "./components/pages/Ranking";
import Events from "./components/pages/Events";
import Reset from "./components/pages/Reset";

function App() {
  return (
    <>
      <Router>
        <ShowNavbar>
          <Navbar />
        </ShowNavbar>
        <Routes>
          <Route path="/" element={<TodayChallenges />} />
          <Route path="/cadastro" element={<Register />} />
          <Route path="/eventos" element={<Events />} />
          <Route path="/login" element={<Login />} />
          <Route path="forbidden" element={<Forbidden />} />
          <Route path="/desafios" element={<Challenges />} />
          <Route path="/participantes" element={<Participantes />} />
          <Route path="/painelAdm" element={<PainelAdmUser />} />
          <Route path="/ranking" element={<Ranking />} />
          <Route path="/reset" element={<Reset />} />
          {/* Outras rotas da sua aplicação */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
