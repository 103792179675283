import Cookies from "js-cookie";
import api from "../lib/api";
import ButtonGreen from "../utils/ButtonGreen";
import { useEffect, useState } from "react";
import Footer from "../utils/Footer";
import { useSearchParams } from 'react-router-dom';
import ButtonOrange from "../utils/ButtonOrange";
import { useMediaQuery } from 'react-responsive';

function Login() {
  const img_desktop = "https://pedroaaugustto.com/wp-content/uploads/2025/01/bg-desktop.webp";
  const img_mobile = "https://pedroaaugustto.com/wp-content/uploads/2025/01/bg-mobile-scaled.webp";
  const logo = "https://pedroaaugustto.com/wp-content/uploads/2025/01/Ativo-1@4x.png";
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const [recoveryPassword, setRecoveryPassword] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    setTimeout(() => {
      setError(null);
    }, 6000);
  }, [error]);

  async function userLogin(login: string, password: string) {
      const userData = {
      login,
      password
    }
    try {
      const response = await api.post("/login", userData, {
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (response.status === 200) {
        const data = response.data;
        Cookies.set("token", data, { expires: 3 });
        window.location.href = "/";
      } else {
        console.error("Erro no servidor:", response.status);
      }
    } catch (error) {
      setError("Email ou senha incorretos");
    }
  }

  useEffect(() => {
    const email = searchParams?.get("email");
    const pass = searchParams?.get("senha");
    if (email && pass) {
      setLogin(email)
      setPassword(pass)
      userLogin(email, pass)
    }
  }, []);

  const handleLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLogin(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const userData = {
      login,
      password
    };

    try {
      const response = await api.post("/login", userData, {
        headers: {
          "Content-Type": "application/json" // Define o cabeçalho Content-Type como application/json
        }
      });

      // Verifique a resposta do servidor, redirecione se necessário
      if (response.status === 200) {
        const data = response.data;
        Cookies.set("token", data, { expires: 3 });
        window.location.href = "/"; // Redireciona para a página inicial
      } else {
        console.error("Erro no servidor:", response.status);
      }
    } catch (error) {
      setError("Email ou senha incorretos");
    }
  }

  async function handleSubmitRecoveryPassword(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const userData = {
      email: login
    };

    try {
      const response = await api.post("/login/recovery", userData, {
        headers: {
          "Content-Type": "application/json" // Define o cabeçalho Content-Type como application/json
        }
      });

      // Verifique a resposta do servidor, redirecione se necessário
      if (response.status === 200) {
        setRecoveryPassword(true)
      } else {
        setError("Email incorreto")
        console.error("Erro no servidor:", response.status);
      }
    } catch (error) {
    }
  }

  return (
    <div className="">
      <div>
        {error && (
          <div
            id="error"
            className="bg-red-500 mt-8 transition-opacity duration-300 text-white p-2 text-center absolute left-[50%] translate-x-[-50%] z-10 rounded"
          >
            <span className="m-16 font-bold">{error}</span>
          </div>
        )}
      </div>
      <div
        className={`min-h-screen flex items-center justify-start bg-cover ${
          isMobile ? "bg-right" : "bg-center"
        }`}
        style={{
          backgroundImage: `url(${isMobile ? img_mobile : img_desktop})`,
        }}
      >
        <div className="bg-white p-6 py-16 rounded-lg shadow-md w-full lg:w-1/4 lg:ml-[15%] mx-10">
          <div className="w-full lg:w-full">
            <div className="flex justify-center">
              <img src={logo} className="w-1/2" />
            </div>
            {!recoveryPassword ? (
              <>
              <form onSubmit={handleSubmit} className="text-black">
              <div className="mb-4 lg:mb-8">
                <label htmlFor="login" className="font-medium">LOGIN:</label>
                <input
                  className="mt-2 lg:mt-1 w-full rounded-lg border-[3px] bg-transparent border-black px-2 py-1 focus:border-[#eb5e29] focus:outline-none"
                  type="text"
                  name="login"
                  id="login"
                  value={login}
                  onChange={handleLoginChange}
                  placeholder="Digite seu login"
                  />
              </div>
              <div className="mb-4 lg:mb-8">
                <label htmlFor="password" className="font-medium">SENHA:</label>
                <input
                  className="mt-2 lg:mt-1 w-full rounded-lg border-[3px] bg-transparent border-black px-2 py-1 focus:border-[#eb5e29] focus:outline-none"
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Digite sua senha"
                  />
              </div>
              <ButtonOrange title="ENTRAR" />
            </form>
            <div className="text-center pt-4 text-black">
              <span onClick={()=> setRecoveryPassword(true)} className={"cursor-pointer"}>Esqueci minha senha</span>
            </div>
            </>
            ):(<>
              <form onSubmit={handleSubmitRecoveryPassword} className="text-black">
              <div className="mb-4 lg:mb-8">
                <label htmlFor="login" className="font-medium">LOGIN:</label>
                <input
                  className="mt-2 lg:mt-1 w-full rounded-lg border-[3px] bg-transparent border-black px-2 py-1 focus:border-[#eb5e29] focus:outline-none"
                  type="text"
                  name="login"
                  id="login"
                  value={login}
                  onChange={handleLoginChange}
                  placeholder="Digite seu login"
                  />
              </div>
              <ButtonOrange title="RECEBER ACESSO NO EMAIL" />
            </form>
            <div className="text-center pt-4 text-black">
              <span onClick={()=> setRecoveryPassword(false)} className={"cursor-pointer"}>Fazer login</span>
            </div>
            </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
