import { useEffect } from "react";
import { getUser, logout } from "../lib/auth";
import "../lib/auth";
import api from "../lib/api";
import Cookies from "js-cookie";

export default function Reset() {
  const user = getUser();

  function logoutUser() {
    logout();
  }

  useEffect(() => {
    async function reset() {
      try {
        const response = await api.post(`/reset/${user.sub}`, {
          headers: {
            "Content-Type": "application/json"
          }
        });

        if (response.status === 200) {

          Cookies.remove("token");
          logoutUser()
          window.location.reload();
        } else {
          console.error("Erro no servidor:", response.status);
        }
      } catch (error) {
        console.log(error);
      }
    }
    reset()
  }, []);

  return (<></>);
}

