import Loader from "./Loader/Loader";

interface ButtonProps {
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void | Promise<void>;
  title: string;
}

export default function ButtonOrange({ disabled, onClick, isLoading, title }: ButtonProps) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className="bg-[#eb5e29] text-white p-2 rounded-xl w-full font-black hover:bg-[#eb5e29] hover:bg-opacity-80 transition-all duration-300 ease-in-out
        disabled:bg-orange-700 disabled:cursor-not-allowed"
    >
      {isLoading ? <Loader /> : <span>{title}</span>}
    </button>
  );
}
